import axios from 'axios';
import { message } from 'antd'
import store from 'store'

const Axios = axios.create();
Axios.defaults.withCredentials = false;
Axios.defaults.timeout = 0;

function getPubliParams() {
    return {
        login_token: store.get('login_token') || '',
        uid: store.get('uid') || ''
    }
}

Axios.interceptors.request.use(
    config => {
        if (config.method === "post") {
            if (config.data instanceof FormData) {
                config.headers["Content-Type"] = "multipart/form-data";
                return config;
            } else {
                config.headers["Content-Type"] =
                    "application/json; charset=UTF-8";
            }
        }
        config.data = { ...getPubliParams(), ...config.data }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);
Axios.interceptors.response.use(
    res => {
        if (res.config.url.indexOf('.json') > -1) {
            return res.data
        }
        const result = res.data;
        if (result.errcode === 0 || result.ret === 0 || result.ret === 1002) {
            return result;
        }
        message.error(`${result.ret}: ${result.user_msg || result.msg || ''}`)
        return Promise.reject({
            message: `${result.ret}: ${result.user_msg || result.msg || ''}`,
            ...result
        });
    },
    err => {
        return Promise.reject(err);
    }
);

export default Axios;