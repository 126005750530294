import React, { Component } from 'react';
import styles from './footer.module.less';

export default class Footer extends Component {
    render() {
        return <footer>
            <div className={styles.topInfo}>
                <div className={styles.leftAddress}>
                    <h2>CÔNG TY TNHH CÔNG NGHỆ MẠNG THÔNG TIN VIỆT NAM</h2>
                    <ul>
                        <li className={styles.address}><label>Địa chỉ:</label> <p>Tòa nhà H2, số 196 Hoàng Diệu, Phường 08, Quận 4, TP.HCM, Việt Nam.</p></li>
                        <li>Số giấy phép: 0314505650</li>
                        <li>Ngày cấp: 2017/07/10</li>
                        <li>Nơi cấp: Sở Kế hoạch và Đầu tư Tp. Hồ Chí Minh</li>
                    </ul>
                </div>
                <div className={styles.rightContact}>
                    <h2>Liên hệ chúng tôi</h2>
                    <ul>
                        <li>Email: contact@vngaynay.vn</li>
                        <li>Phone: +84 36362900</li>
                        <li>Zalo: 0932728536</li>
                    </ul>
                    <div>
                        <a style={{ color: "#4457B4", fontSize: '18px' }} href={require('../../assets/useraggrement.pdf')} target="_blank" rel="noopener noreferrer">Thỏa Thuận Cung Cấp và Sử Dụng Dịch Vụ</a>
                    </div>
                </div>
            </div>
            <div className={styles.bottomInfo}>
                <p>Giấy phép thiết lập mạng xã hội trên mạng, Số 357/GP – BTTTT do Bộ Thông tin và Truyền thông cấp, thay đổi lần 1 ngày 04/10/2018 <br />
                    Chịu trách nhiệm nội dung: Lê Phạm Huyền Nhung</p>
            </div>
        </footer>
    }
}
