import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from './header.module.less';
import Cookies from 'js-cookie'
import store from 'store'
import { history } from '../../route'

import Login from '../login';

export default class Header extends Component {
    state = {
        loginVisible: false,
        userName: '',
        isLogin: store.get('login_token'),
    }

    componentDidMount() {
        const userInfo = store.get('userInfo')
        userInfo && this.setState({
            userName: userInfo.nickname
        })
    }

    componentWillUnmount() {
    }

    handleSuccess = () => {
        this.setState({
            isLogin: true
        })
        this.componentDidMount()
    }

    showLogin = () => {
        this.setState({ loginVisible: true, noUser: false })
    }
    closeVisible = () => {
        this.setState({ loginVisible: false })
    }

    loginOut = () => {
        store.remove('uid')
        store.remove('login_token')
        this.setState({
            isLogin: false
        })
    }

    render() {
        return <header >
            <div className={styles.vn_header}>
                <div className={styles.vn_header_left}>
                    <Link to="/home"><img src={require('../../assets/images/logo.png')} alt="vnnn" className={styles.vn_logo} /></Link>
                </div>
                <div className={styles.vn_header_right}>
                    {this.state.isLogin ? <Link className={styles.vn_publish} to="/publish">Đăng bài</Link> : ''}
                    {this.state.isLogin ? <span className={styles.vn_name} onClick={() => history.push('/my')}>{this.state.userName}</span> : <span className={styles.vn_sign} onClick={this.showLogin}>Đăng nhập</span>}
                    {this.state.isLogin ? <span className={styles.vn_sign} onClick={this.loginOut}>Thoát</span> : <Link className={styles.vn_sign} to="/register">Đăng ký</Link>}
                </div>
            </div>
            <Login loginSuccess={this.handleSuccess} loginVisible={this.state.loginVisible} closeVisible={this.closeVisible} />
        </header>
    }
}


