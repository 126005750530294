import React, { Component } from "react";
import { List, Card } from "antd";
import Footer from "../../components/footer/index";
import { Link } from "react-router-dom";
import request from "@/utils/request";
import styles from "./index.module.less";

export default class Home extends Component {
  state = {
    homeData: [],
    loading1: false,
    loading2: false,
    loading3: false,
    loading4: false,
  };

  componentDidMount() {
    const that = this;
    this.setState({
      loading1: true,
      loading2: true,
      loading3: true,
      loading4: true,
    });
    request({
      url: "/v1/moments/list",
      method: "POST",
      data: {
        cat_id: 0,
      },
    })
      .then((res) => {
        that.setState({ list1: res.data, loading1: false });
      })
      .catch(() => {
        that.setState({ list1: [], loading1: false });
      });

    request({
      url: "/v1/moments/list",
      method: "POST",
      data: {
        cat_id: 6,
      },
    })
      .then((res) => {
        that.setState({ list2: res.data, loading2: false });
      })
      .catch(() => {
        that.setState({ list2: [], loading1: false });
      });

    request({
      url: "/v1/moments/list",
      method: "POST",
      data: {
        cat_id: 2,
      },
    })
      .then((res) => {
        that.setState({ list3: res.data, loading3: false });
      })
      .catch(() => {
        that.setState({ list3: [], loading3: false });
      });

    request({
      url: "/v1/moments/list",
      method: "POST",
      data: {
        cat_id: 3,
      },
    })
      .then((res) => {
        that.setState({ list4: res.data, loading4: false });
      })
      .catch(() => {
        that.setState({ list4: [], loading4: false });
      });
  }

  render() {
    const { homeData, homeLoading } = this.state;
    return (
      <div className={styles.homeWrapper}>
        <h1 style={{ textAlign: "center", marginTop: "80px" }}>
          Hệ thống đang bảo trì nâng cấp ...
        </h1>
        {
          // ['Mới nhất', 'Kinh tế', 'Xã hội', 'Giải trí'].map((_item, index) => (
          //     <Card title={<span className={styles.cardTitle}>{_item}</span>}>
          //         <List itemLayout="horizontal"
          //             loading={this.state['loading' + (index + 1)]}
          //             dataSource={(this.state['list' + (index + 1)] || []).filter(i => !!i)}
          //             renderItem={item => (
          //                 <List.Item>
          //                     <div className={styles.newsWrapper}>
          //                         <div className={styles.newstitle}><Link style={{ color: '#000' }} to={`/content?doc_id=${item.doc_id}&content_id=${item.content_id}&ct=${item.pub_utc}`}>{item.title}</Link></div>
          //                         <div className={styles.newsAuthor}>{item.author.nickname}</div>
          //                         <div className={styles.newsCategory}>{_item}</div>
          //                     </div>
          //                 </List.Item>
          //             )}
          //         />
          //     </Card>
          // ))
        }
        <Footer />
      </div>
    );
  }
}
