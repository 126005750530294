import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from '@/utils/asyncComponent';
import Header from '@/components/header';
import { createHashHistory }  from 'history'

import Home from '@/page/home';

const Content = asyncComponent(() => import('@/page/content'));
const Publish = asyncComponent(() => import('@/page/publish'));
const Register = asyncComponent(() => import('@/page/register'));
const My = asyncComponent(() => import('@/page/my'));
const Edit = asyncComponent(() => import('@/page/edit'));

export const history = createHashHistory()

export default class RouteConfig extends Component {
    render() {
        return (
            <Router history={history}>
                <Header />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/home" component={Home} />
                    <Route path="/content"  component={Content} />
                    <Route path="/publish"  component={Publish} />
                    <Route path="/register"  component={Register} />
                    <Route path="/edit"  component={Edit} />
                    <Route path="/my"  component={My} />
                    <Redirect to="/home" />
                </Switch>
            </Router>
        )
    }
}