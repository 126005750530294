import React, { Component } from 'react';
import { Modal, Input, Checkbox, Button, Select, message, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import request from '@/utils/request';
import styles from './index.module.less';
import store from 'store'
import Cookies from 'js-cookie'

const Option = Select.Option;
let loginCounts = 60;
let loginTimer = null;

class Login extends Component {
    constructor() {
        super();
        this.state = {
            phone: '',
            code: '',
            loginLoading: false,
            noUser: false,
            prePhone: '+84',
            codeText: 'Nhận mã'
        }
    }

    closeVisible = () => {
        this.props.closeVisible();
        loginCounts = 60;
    }

    sendCode = () => {
        const { phone, prePhone } = this.state;
        if (loginCounts < 60) {
            return;
        }
        if (phone && phone.length > 6) {
            let params = {
                phone: prePhone + phone,
                verify_type: 'login',
                source: 'web'
            }
            request({
                // url: 'https://phone.headlines.pw/v1/verify_code/send',
                url: '/v1/verify_code/send',
                method: 'POST',
                data: params
            }).then(res => {
                clearInterval(loginTimer);
                if (res.ret === 0) {
                    const that = this;
                    loginTimer = setInterval(function () {
                        loginCounts--;
                        that.setState({ codeText: `${loginCounts}s` })
                        if (loginCounts === 0) {
                            clearTimeout(loginTimer);
                            that.setState({ codeText: `Nhận mã` })
                            loginCounts = 60;
                        }
                    }, 1000)
                } else {
                    message.error('Số điện thoại sai định dạng !');
                }
            }).catch(e => {
                message.error('Số điện thoại sai định dạng !');
            })
        } else {
            message.error('Số điện thoại sai định dạng !');
            return false;
        }
    }

    loginSubmit = () => {
        const { phone, code, prePhone } = this.state;
        const that = this;
        this.setState({ loginLoading: true })
        let params = {
            phone: prePhone + phone,
            verify_code: code,
            source: 'web',
            uid: 0,
        }
        request({
            url: 'https://user.headlines.pw/v1/login/check_phone',
            // url: 'https://phone.headlines.pw/v1/verify_code/check',
            method: 'POST',
            data: { phone: prePhone + phone }
        }).then(res => {
            if (res.ret === 1002){
                return request({
                    url: 'https://1.headlines.pw/web/v1/login/phone',
                    method: 'POST',
                    data: params
                })
                .then(res => {
                    store.set('uid', res.data.user.id)
                    store.set('login_token', res.data.login_token)
                    store.set('userInfo', res.data.user)
                    message.success('Đăng nhập thành công!')
                    this.props.loginSuccess()
                    this.setState({ loginLoading: false })
                    this.closeVisible();
                })
            } else {
                this.setState({
                    loginLoading: false,
                    noUser: true
                })
            }
        })
    }

    goRegister = () => {
        const that = this;
        this.closeVisible();
        this.props.history.push('/register');
        that.setState({ loginLoading: false });
    }

    selectLongLogin = (e) => {
        localStorage.setItem('longLogin', e.target.checked);
        localStorage.setItem('longLoginPhone', this.state.phone);
        this.setState({ userCheck: e.target.checked })
    }

    render() {

        const prefixSelector = <Select style={{ width: 70 }} defaultValue={this.state.prePhone} onChange={e => this.setState({ prePhone: e })}>
            <Option value="+86">+86</Option>
            <Option value="+84">+84</Option>
        </Select>;

        return <Modal key="login"
            title="Đăng nhập"
            width="40vw"
            visible={this.props.loginVisible}
            onCancel={this.closeVisible}
            footer={null}
            closeIcon={<span className={styles.closeBtn}>X</span>}
        >
            {this.state.noUser ? <div className={styles.noUserBox}>
                <div className={styles.noInfo}><Icon type="info-circle" />Số điện thoại chưa được sử dụng, hãy đăng ký tài khoản？</div>
                <div className={styles.btnGroup}>
                    <Button className={styles.userBtn} onClick={() => this.setState({ loginVisible: false, noUser: false })}>Hủy bỏ</Button>
                    <Button className={styles.userBtn} onClick={this.goRegister}>Đăng ký</Button>
                </div>
            </div> : <div className={styles.loginWrapper}>
                    <div className={styles.loginItem}>
                        <span className={styles.label}>Số điện thoại :</span>
                        <Input value={this.state.phone} onChange={e => this.setState({ phone: e.target.value })} addonBefore={prefixSelector} placeholder="Vui lòng nhập số điện thoại" className={styles.input} />
                    </div>
                    <div className={styles.loginItem}>
                        <span className={styles.label}>Mã xác nhận :</span>
                        <Input value={this.state.code} onChange={e => this.setState({ code: e.target.value })} addonAfter={<span className={styles.sendCode} onClick={this.sendCode}>{this.state.codeText}</span>} placeholder="Vui long nhap ma xac nhan" className={styles.input} />
                    </div>
                    {/* <div className={styles.loginItem}>
                        <div className={styles.center}>
                            <Checkbox checked={this.state.userCheck} onClick={this.selectLongLogin} /> <span>Nhớ trạng thái đăng nhập</span>
                        </div>
                    </div> */}
                    <Button loading={this.state.loginLoading} className={styles.loginBtn} onClick={this.loginSubmit}>Đăng nhập</Button>
                    <span className={styles.helpInfo}>Chưa có tài khoản? <Link style={{color: '#C10000'}} to="/register" onClick={this.closeVisible}>Đăng ký</Link></span>
                </div>}
        </Modal>
    }
}

export default withRouter(Login)